import React, { Fragment, useState, useEffect } from "react";
import ActionBar from "./ActionBar";
import _ from "lodash";
import { navigate, useLocation } from "@reach/router";
import styled from "styled-components";
import themes from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import theme from "../../../assets/theme";
import AlertDialog from "../../common/Alert";
import TranscriptEditor from "../../transcripts/TranscriptEditor/index";

const TranscriptEdit = (id) => {
  const history = useLocation();
  const resolutionKind = history.search.replace("?caseType=", "");
  const breadcrumbs = ["Cases", _.startCase(resolutionKind), "Transcript"];
  const [transcriptData, setTranscriptData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const caseId = id?.id;
  const [data, setData] = useState([
    {
      id: "",
      start: "",
      end: "",
      text: "",
      speaker: "",
      confidence: "",
      count: 0,
      mediaId: "",
      transcriptionFlag: "",
    },
  ]);
  const [meetingId, setMeetingId] = useState();
  const [transcriptionStatus, setTranscriptionStatus] = useState();
  const [dialogData, setDialogData] = useState({});
  const [open, setOpen] = useState(false);
  const [discard, setDiscard] = useState([
    {
      id: "",
      start: "",
      end: "",
      text: "",
      speaker: "",
      confidence: "",
      mediaId: "",
      transcriptionFlag: "",
    },
  ]);
  const [refreshDetails, setRefreshDetails] = useState(true);
  const [render, setRender] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [filterSpeaker, setFilterSpeaker] = useState();
  let LiveTranscription = true;

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case resolutionKind:
        navigate(`/dashboard/cases/${id?.id}?caseType=${resolutionKind}`);
        break;
      case "cases":
        navigate(`/dashboard/cases?caseType=${resolutionKind}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    async function getData(caseId) {
      try {
        setLoader({ state: true, message: "Fetching Transcription..." });
        const transcriptionControl = await CaseService.GetMeetingcases(caseId);
        const res = await CaseService.getLiveTranscriptionData(caseId);
        if (res) {
          const lastObject =
            res?.transcriptionData?.words[
              res?.transcriptionData?.words?.length - 1
            ];

          const newObject = { ...lastObject, text: "End Of Transcript" };
          if (
            res?.transcriptionData?.words?.length > 0 &&
            transcriptionControl?.transcriptionRunningStatus !== true
          ) {
            setData([
              ...res?.transcriptionData?.words?.map((el) => {
                return {
                  id: el?.id,
                  end: el?.end,
                  confidence: el?.confidence,
                  text: el?.text,
                  start: el?.start,
                  speaker: el?.speaker,
                  mediaId: el?.mediaId,
                  transcriptionFlag: el?.transcriptionFlag,
                };
              }),
              newObject,
            ]);
            setDiscard([
              ...res?.transcriptionData?.words?.map((el) => {
                return {
                  id: el?.id,
                  end: el?.end,
                  confidence: el?.confidence,
                  text: el?.text,
                  start: el?.start,
                  speaker: el?.speaker,
                  mediaId: el?.mediaId,
                  transcriptionFlag: el?.transcriptionFlag,
                };
              }),
              newObject,
            ]);
          } else {
            setData(
              res?.transcriptionData?.words?.map((el) => {
                return {
                  id: el?.id,
                  end: el?.end,
                  confidence: el?.confidence,
                  text: el?.text,
                  start: el?.start,
                  speaker: el?.speaker,
                  mediaId: el?.mediaId,
                  transcriptionFlag: el?.transcriptionFlag,
                };
              }),
            );
            setDiscard(
              res?.transcriptionData?.words?.map((el) => {
                return {
                  id: el?.id,
                  end: el?.end,
                  confidence: el?.confidence,
                  text: el?.text,
                  start: el?.start,
                  speaker: el?.speaker,
                  mediaId: el?.mediaId,
                  transcriptionFlag: el?.transcriptionFlag,
                };
              }),
            );
          }
          setMeetingId(res?.meetingId);
          setTranscriptData(res);
          setRender(true);
        } else {
          setTranscriptData();
        }
        getTranscriptionStatus(res?.meetingId);
      } catch (error) {
        console.log("failed");
      } finally {
        setLoader({ state: false });
      }
    }
    if (refreshDetails) {
      getData(caseId);
      setRefreshDetails(false);
    }
  }, [refreshDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const interval = setInterval(() => {
      getTranscriptionStatus(meetingId);
    }, 120000);
    return () => clearInterval(interval);
  }, [meetingId]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateLiveTranscriptionData = async (data) => {
    try {
      setLoader({ state: true, message: "Adding Transcript..." });
      let storedData = data;
      storedData.pop();
      const payload = {
        editedTranscriptionData: storedData,
        speakerNameObj: filterSpeaker,
      };
      if (storedData?.length) {
        const res = await CaseService.updateLiveTranscriptionData(
          payload,
          meetingId,
        );
        if (res?.updated) {
          setRefreshDetails(true);
        }
        if (res?.message) {
          enqueueSnackbar(res?.message, {
            variant: "success",
          });
        }
      } else {
        setRefreshDetails(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  const getTranscriptionStatus = async (meetingId) => {
    try {
      const response = await CaseService.getTranscriptionStatus(meetingId);
      if (response) {
        setTranscriptionStatus(response?.transcriptionStatus);
        setAlertMessage(response?.alert);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  };

  const moveTranscriptToDoc = async (meetingId) => {
    try {
      setLoader({ state: true, message: "Transcript moved" });
      const response = await CaseService.moveTranscriptToDoc(meetingId);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      setTimeout(() => {
        setRefreshDetails(true);
      }, 1800);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
    }
  };

  const saveAndmoveTranscript = async (meetingId) => {
    try {
      setLoader({ state: true, message: "Transcript moved" });
      let storedData = data;
      storedData.pop();
      const payload = {
        editedTranscriptionData: storedData,
      };
      const response = await CaseService.updateLiveTranscriptionData(
        payload,
        meetingId,
      );
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      moveTranscriptToDoc(meetingId);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
    }
  };

  const discardAndMove = (meetingId) => {
    setData(
      discard.map((el) => {
        return {
          id: el?.id,
          end: el?.end,
          confidence: el?.confidence,
          text: el?.text,
          start: el?.start,
          speaker: el?.speaker,
          mediaId: el?.mediaId,
          transcriptionFlag: el?.transcriptionFlag,
        };
      }),
    );
    if (data) {
      moveTranscriptToDoc(meetingId);
    }
  };

  const moveTranscriptionAlert = (meetingId, alertMessage) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Discard and Move",
      secondaryBtnText: "Cancel",
      moveTranscriptBtnText: "Save and Move",
      clickSecondarybtn: () => setOpen(false),
      clickPrimaryBtn: () => discardAndMove(meetingId),
      movePrimaryBtn: () => saveAndmoveTranscript(meetingId),
      onDialogClose: () => setOpen(false),
      desc: (
        <>
          Are you sure you want to move the transcript to case documents? <br />
          <br />
          <b style={{ fontFamily: `${theme.fonts.primaryFontExtraBold}` }}>
            Note :
          </b>{" "}
          <b
            style={{
              fontFamily: theme.fonts.primaryFontExtraBold,
              color: COLORS.LOGOUT_RED,
            }}
          >
            {
              "Please make sure you save all your changes before moving the transcript."
            }
          </b>
          <br />
          <br />
          <b
            style={{
              fontFamily: theme.fonts.primaryFontExtraBold,
              color: COLORS.LOGOUT_RED,
            }}
          >
            {alertMessage}
          </b>
        </>
      ),
      moveTranscript: true,
      heading: `Move Transcription`,
      descriptionTextStyle: {
        textAlign: "left",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  const discardChanges = () => {
    setData(
      discard.map((el) => {
        return {
          id: el?.id,
          end: el?.end,
          confidence: el?.confidence,
          text: el?.text,
          start: el?.start,
          speaker: el?.speaker,
          mediaId: el?.mediaId,
        };
      }),
    );
    setRender(true);
  };

  const actions = [
    {
      text: "Post Transcript",
      props: {
        onClick: () => moveTranscriptionAlert(meetingId, alertMessage),
      },
      outlined: true,
      hidden: transcriptionStatus && data ? false : true,
    },
    {
      text: "Refresh",
      props: {
        onClick: () => setRefreshDetails(true),
      },
      outlined: true,
      // hidden: transcriptData?.audioFileUrl && data ? true : false,
    },
    {
      text: "Discard",
      props: {
        onClick: () => discardChanges(),
      },
      hidden: data ? false : true,
    },
    {
      text: "Save (alt + s)",
      props: {
        onClick: () => updateLiveTranscriptionData(data),
      },
      hidden: data ? false : true,
    },
  ];

  return (
    <Fragment>
      <ActionBar
        {...{ breadcrumbs, onBreadcrumbClick, actions, transcriptData }}
      />
      <Container>
        {/* <Heading>
          <BackArrow
            onClick={() =>
              navigate(`/dashboard/cases/${id?.id}?caseType=${resolutionKind}`)
            }
            src={require("../../../assets/images/backArrow.svg")}
          />
          {"Transcription"}{" "}
          <p style={{ marginLeft: "10px" }}>
            {transcriptData?.timeInterval
              ? `(${transcriptData?.timeInterval} Minutes)`
              : ""}
          </p>
        </Heading>
        <Heading>
          {transcriptionStatus && data ? (
            <PrimaryCTAButtonRefresh
              style={{ marginLeft: "350px", marginRight: "20px" }}
              onClick={() => moveTranscriptionAlert(meetingId)}
            >
              Move Transcript
            </PrimaryCTAButtonRefresh>
          ) : null}{" "}
          <PrimaryCTAButtonRefresh
            style={{
              marginLeft: transcriptionStatus ? "0px" : "650px",
              marginRight: "20px",
            }}
            onClick={() => setRefreshDetails(true)}
          >
            Refresh
          </PrimaryCTAButtonRefresh>{" "}
          {data ? (
            <PrimaryCTAButtonRefresh onClick={() => discardChanges()}>
              Discard
            </PrimaryCTAButtonRefresh>
          ) : null}{" "}
          {transcriptData?.audioFileUrl && data ? (
            <PrimaryCTAButtonRefresh
              style={{
                marginLeft: "20px",
              }}
              onClick={() => {
                updateLiveTranscriptionData(data);
              }}
            >
              SAVE
            </PrimaryCTAButtonRefresh>
          ) : (
            ""
          )}
        </Heading> */}
        {data ? (
          <TranscriptEditor
            {...{
              caseId,
              meetingId,
              data,
              render,
              setRender,
              setFilterSpeaker,
              LiveTranscription,
            }}
            mediaUrl={transcriptData?.audioFileUrl}
            setData={setData}
            title={history?.state?.title}
            updateState={updateLiveTranscriptionData}
          />
        ) : (
          <StyledTableEmptyBlock>
            <h2>
              {
                "Welcome to IIAC live transcription services. Your session has either not started or your live transcript is currently being generated."
              }
            </h2>
          </StyledTableEmptyBlock>
        )}
      </Container>
      <AlertDialog
        isOpen={open}
        {...{ ...dialogData }}
        descriptionTextStyle={{
          textAlign: "left",
          fontFamily: theme.fonts.primaryFontSemiBold,
        }}
      />
    </Fragment>
  );
};

export default TranscriptEdit;

export const Container = styled.div`
  padding: 4px 43px;
  display: flex;
  flex-direction: column;
`;

export const HeadingContainer = styled.span`
  margin-bottom: 31px;
  font-family: ${themes.fonts.primaryFontSemiBold};
  font-size: ${(props) => (props.size ? props.size : "13px")};
  color: ${COLORS.BTN_GREEN};
  // text-transform: uppercase;
  cursor: pointer;
  & .topSection {
    background: ${COLORS.BOX_DARKGREEN};
  }
`;

const StyledTableEmptyBlock = styled.div`
  position: absolute;
  top: 55%;
  left: 55%;
  transform: translate(-40%, -40%);
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    color: ${COLORS.COLOR_DARK};
    font-weight: 500;
    letter-spacing: 0.1px;
    font-size: 20px;
    margin: 0;
    text-align: center;
    font-family: ${theme.fonts.primaryFontBold};
  }
  p {
    margin: 10px auto 30px;
    font-family: ${theme.fonts.primaryFontRegular};
    color: ${COLORS.COLOR_DARK};
  }
  a {
    color: ${COLORS.BTN_GREEN};
    font-family: ${theme.fonts.primaryFontRegular};
  }
`;
